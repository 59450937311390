body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.btn{
  width: 150px;
  padding: 15px 5px;
  color: white;
  /* border-radius: 10px; */
  margin: 10px;
  background-color: #0a1647;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.white{
  background-color: white;
  color: #0a1647; 
}
.center{
  text-align: center;
}

.mt-10{
  margin-left: 40px;
  margin-top: 50px;
  
}

.title{
  font-size: 24px;
}