body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.btn{
  width: 150px;
  padding: 15px 5px;
  color: white;
  /* border-radius: 10px; */
  margin: 10px;
  background-color: #0a1647;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.white{
  background-color: white;
  color: #0a1647; 
}
.center{
  text-align: center;
}

.mt-10{
  margin-left: 40px;
  margin-top: 50px;
  
}

.title{
  font-size: 24px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar{
  position: absolute;
  width: 100%;
}

.navbar ul{
  list-style: none;
  display: flex;
}
.navbar ul li{
  padding: 10px;
  color: white;
}

.navbar ul li a {
  color: white;
  text-decoration: none
}
.container{
  padding: 30px;
  padding-top:80px;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h3 {
    margin: -6px;
    font-size: 24px;
}

section{
    color: white;
    height: 100vh;
    /* padding: 20px;     */
    
}
.container{
    background-color: #0a1647;
}

.hero{

    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    height: 80%;
    
}

.hero .heading{
    padding: 30px;

}

.hero .image{
    padding: 30px;
    display: block;
    width: 50%;
}

.hero h1 {
    font-size: 44px;
}
.hero h2{
    margin-top: -20px;
    font-size: 28px;
}

.image img{
    display: block;
    margin: 0 auto;
    width: 80%;
}
.ipfs-container{
    background-color: white;
    padding: 20px;

}

.btn{
    border:none;
    padding: 10px;
    border-radius: 20px;
    font-size: 18px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(36, 63, 184) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type="text"]{
  padding: 10px;
  border: none;
  border-bottom: 2px solid #EC407A;
}
.list-container{
    width:100%;
    margin: 10px;  
}

.list-item{
    padding: 20px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    display: flex;
    flex-direction: column;
    align-items : flex-start;
    max-width: 80%;
    margin: 10px auto;
}



.subtitle{
    font-size: 18px;
}

.details{
    display: block;
    text-align: left;
    margin: 0px 10px;
    margin-bottom: 20px;
}

.address{
    margin : 10px 0;    
    width: 100%;
}

.address .actions{
    justify-content: space-between
}
.address .actions #author a{
    color: darkblue;
}

.address .actions div{
    margin: 10px;
}

.actions{
    display: flex;
    width: 100%;
    justify-content: space-around;
    text-align: center;
    cursor: pointer;
}
.actions a{
    color: white;
    text-decoration: none;
}

.cl-w{
    color: white;
}
.author-container{
    width:100%;
    margin: 10px;  
    color: white;
    height: 50vh;
}

.author-container .title{
    font-size: 42px;
    margin: 20px;
    margin-bottom : 40px;
}




.analytics{
    display: flex;
    flex:1 1;
    width: 90%;
    margin: 30px auto;
    justify-content: space-between;
}

.analytics .card{
    background-color: white;
    width: 20%;
    text-align: center;
    color: black;
    padding: 10px;
}

.card .heading{
    font-size: 16px;
}

.card .value{
    padding: 5px;
    font-size: 32px;
}
.author{
    color: darkblue;
}

