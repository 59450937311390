.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar{
  position: absolute;
  width: 100%;
}

.navbar ul{
  list-style: none;
  display: flex;
}
.navbar ul li{
  padding: 10px;
  color: white;
}

.navbar ul li a {
  color: white;
  text-decoration: none
}
.container{
  padding: 30px;
  padding-top:80px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
